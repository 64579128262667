import React from "react";
import VaseFlower from "./assets/flowerVase.jpg";
import AsianStudiesLogo from "./assets/asianstudies.png";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
// import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#002144",
      },
    },
  });

  return (
    <div className="relative min-h-screen flex flex-col bg-dark">
      <div
        className="absolute inset-0 bg-gray-800 text-white"
        style={{
          backgroundImage: `url(${VaseFlower})`,
          backgroundRepeat: "repeat",
          backgroundSize: "cover",
          filter: "brightness(40%)",
        }}
      ></div>

      <div className="flex-1 flex items-center pt-10 sm:py-20 text-center flex-col text-white z-10">
        <div className="flex max-w-xs flex-col bg-dark p-6 sm:p-12 sm:max-w-xl xl:max-w-5xl rounded-xl space-y-4 shadow-xl">
          <img
            src={AsianStudiesLogo}
            width={150}
            className="rounded-full mx-auto"
            alt="logo"
          />
          <h1 className="text-4xl sm:text-7xl text-white">Interline Reader</h1>
          <p className="text-lg sm:text-2xl text-gray-200">
            Get assistance reading challenging literary texts from foreign
            languages.
          </p>
          <ThemeProvider theme={theme}>
            <div className="flex flex-col sm:flex-row justify-center mt-8 space-y-4 sm:space-x-4 sm:space-y-0 pt-4 font-bold font-roboto">
              <Button
                href="https://korninterlinereader.asia.ubc.ca/korn351"
                variant="contained"
                color="primary"
                size="large"
              >
                KORN 351
              </Button>
              <Button
                href="https://korninterlinereader.asia.ubc.ca/korn410"
                variant="contained"
                color="primary"
                size="large"
              >
                KORN 410
              </Button>
              <Button
                href="https://korninterlinereader.asia.ubc.ca/korn420"
                variant="contained"
                color="primary"
                size="large"
              >
                KORN 420
              </Button>
            </div>
            <div className="mt-6">
              <Button
                href="https://koreangrammaticalforms.com/"
                variant="contained"
                color="primary"
                size="large"
              >
                Korean Grammatical Forms
              </Button>
            </div>
          </ThemeProvider>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default App;
